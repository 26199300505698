import "./skill.css";
import Frontend from "./Frontend";
import Backend from "./Backend";

const Skills = () => {
  return (
    <section className="skills section" id="skills">

            <h3 className="section__title"> Skills </h3>
            <span className="section__subtitle"> My Technical Level </span>

            <div className="skills__container container grid">
                <Backend />
                <Frontend />
            </div>

    </section>
  )
}

export default Skills