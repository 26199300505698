const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.linkedin.com/in/mohammed-hamzza/"
        rel="noreferrer"
        className="home__social-icon"
        target="_blank"
      >
        <i style={{ color: "blue" }} className="uil uil-linkedin"></i>
      </a>
      <a
        href="https://gitlab.com/Mohammed12"
        rel="noreferrer"
        className="home__social-icon"
        target="_blank"
      >
        <i style={{ color: "orange" }} className="uil uil-gitlab"></i>
      </a>
      <a
        href="https://github.com/mohammedhamza2017"
        rel="noreferrer"
        className="home__social-icon"
        target="_blank"
      >
        <i className="uil uil-github-alt"></i>
      </a>
    </div>
  );
};

export default Social;
