import Work1 from "./../../assets/work1.png";
// import Work2 from "./../../assets/work-002.png";
import Work3 from "./../../assets/work3.png";
import Work4 from "./../../assets/work4.png";
import Work11 from "./../../assets/work01.png";
import Work44 from "./../../assets/work02.png";
// import Work55 from "./../../assets/work05.png";
import Work33 from "./../../assets/work03.png";

export const projectData = [
  {
    id: 1,
    image: Work11,
    title: "YAMA ETHIOPIA Tour & Travels ",
    link: "https://yamahatripsandtours.com",
    category: "web",
  },
  {
    id: 2,
    image: Work44,
    title: "ROAR Fitness official Website",
    link: "https://roerfitness.com",
    category: "web",
  },
  {
    id: 3,
    image: Work1,
    title: "Xclusive Garment E-commerce Website",
    link: "https://xclusivegarment.com/account.php",
    category: "web",
  },
  {
    id: 4,
    image: Work33,
    title: "CHANDELIER DUBAI LIGHTS Store",
    link: "https://chandelierdubai.com",
    category: "web",
  },
  {
    id: 5,
    image: Work4,
    title: "Wow Burger Landing Page (React)",
    link: "https://ethiowowburger.pages.dev",
    category: "design",
  },
  {
    id: 6,
    image: Work3,
    title: "Admin Dashboard Landing Page (React)",
    link: "https://adorable-queijadas-b19d5e.netlify.app",
    category: "design",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
