import "./service.css";
import { useState } from "react"

const Services = () => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <section className="services section" id="services">
            <h3 className="section__title"> Services </h3>
            <span className="section__subtitle"> What Can I Offer ?  </span>

            <div className="services__container container grid">

                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Website <br /> Designer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(1)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 1 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>Website Designer</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing you quality 
                              work to clients and your companies.</p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Web page development.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">I develop better user interfaces.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">I work on your company brand.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Design and mockups of products for companies.</p>
                                  </li>

                                </ul>
                      </div>
                    </div>
                </div>


                <div className="services__content">
                    <div>
                    <i className="uil uil-arrow services__icon"></i>
                    <h3 className="services__title">UI/UX <br/> Designer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(2)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 2 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>UI/UX Designer</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing quality 
                              work to clients and your companies.</p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">I develop better user interfaces using Figma.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Developing the Web page as UI exactly.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Better company branding.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Design and mockups of products for companies.</p>
                                  </li>

                                </ul>
                      </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                    <i className="uil uil-edit services__icon"></i>
                    <h3 className="services__title">Graphic <br /> Designer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(3)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 3 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>Graphic Designer</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing quality 
                              work to clients and your companies. </p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Designing a better Logo for your brand. </p>
                                  </li>


                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Creating a cool banners for your Ads.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Design and mockups of products for companies.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Better branding methodology.</p>
                                  </li>


                                </ul>
                      </div>
                    </div>
                </div>

            </div>
    </section>
  )
}

export default Services