

const Frontend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title" style={{color: "blue", "font-weight": "bold"}}>Backend</h3>
      <div className="skills__box">

           <div className="skills__group">
              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">PHP</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">Node Js</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">Express</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
              </div>
           </div>

           <div className="skills__group">
              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">Golang</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">MySQL</h3>
                    <span className="skills__level">Basics</span>
                  </div>
              </div>


              <div className="skills__data">
                  <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">NoSQL</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
              </div>
           </div>
      </div>
    </div>
  )
}

export default Frontend